import { DfnsError } from '@dfns/sdk/dfnsError'
import { CreateWalletBody, GetWalletHistoryResponse, GetWalletResponse, ListTransactionsResponse, ListTransfersResponse, TransferAssetBody, User } from '@dfns/sdk/types/wallets'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import '../globals.css'
import { dfnsApi } from '../api'
import Modal from '../components/modal_transfer'
import { UserStatuses } from '../types/apptypes'
import useAuth from '../hooks/useAuth'
import ModalField from '../components/modal_reason'
import validator from 'validator'
import { ShortApproval } from '@dfns/sdk/generated/policies/types'



type Asset = {
    kind: string;
    symbol?: string | undefined;
    decimals: number;
    balance: string;
    verified?: boolean | undefined;
    assetId?: string | undefined;
    issuer?: string | undefined;
    assetCode?: string | undefined;
    contract?: string | undefined;
    mint?: string | undefined;
    master?: string | undefined;
    tokenId?: string | undefined;
}



export function convertBalance(balance: string, decimals: number): string {
    const divisor = Math.pow(10, decimals);
    const result = (parseFloat(balance) / divisor).toString();
    return result
}

function convertStringToBalance(balance: string, decimals: number): string {
    const multiplier = Math.pow(10, decimals);
    const result = (parseFloat(balance) * multiplier).toFixed(0);
    return result;
}



function getResponseByKind(asset: Asset, to: string, amount: string): TransferAssetBody {
    switch (asset.kind) {
        case "Native":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
            }
        case "Erc20":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
                contract: asset.contract ?? ""
            }
        case  "Erc721":
            return {
                kind: asset.kind,
                to: to,
                contract: asset.contract ?? "",
                tokenId: asset.tokenId ?? "",
            }
        case  "Trc10":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
                tokenId: asset.tokenId ?? "",
            }
        case "Trc20":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
                contract: asset.contract ?? ""
            }
        case  "Trc721":
            return {
                kind: asset.kind,
                to: to,
                contract: asset.contract ?? "",
                tokenId: asset.tokenId ?? "",
            }
        case  "Asa":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
                assetId: asset.assetId ?? ""
            }
        case  "Sep41":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
                issuer: asset.issuer ?? "",
                assetCode: asset.assetCode ?? "",
            }
        case "Spl" || "Spl2022":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
                mint: asset.mint ?? "",
            }
        case  "Tep74":
            return {
                kind: asset.kind,
                to: to,
                amount: amount,
                master: asset.master ?? "",
            }
    default:
        throw new Error(`Unsupported asset kind: ${asset.kind}`);
    }
}

export default function Wallet(): JSX.Element {
  const { user, logout } = useAuth()
  
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<DfnsError | undefined>(undefined)
  const [wallet, setWallet] = useState<GetWalletResponse | undefined>(undefined)
  const [assets, setAssets] = useState<Asset[]>([])
  const [assetCurrent, setAssetCurrent] = useState<Asset | undefined>(undefined)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { walletId } = useParams<Record<string, string | undefined>>();
  const [approvers, setApprovers] = useState<User[]>([])

  const [transfers, setTransfers] = useState<ListTransfersResponse | undefined>(undefined)
  const [history, setHistory] = useState<GetWalletHistoryResponse | undefined>(undefined)

  const [submittingDeleteApprover, setSubmittingDeleteApprover] = useState<boolean>(false)

  const [isModalAddAproverOpen, setIsAddApproverModalOpen] = useState<boolean>(false)
  const [submittingAddApprover, setSubmittingAddApprover] = useState<boolean>(false)

  const [approvals, setApprovals] = useState<ShortApproval[]>([])

  const [message, setMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    dfnsApi()
      .wallets.getWallet({ walletId: walletId ?? ""})
      .then((resp) => {
        setWallet(resp)
      })
      .catch((err) => {
        setError(err)
      })
  }, [])

  useEffect(() => {
    dfnsApi()
      .wallets.listTransfers({ walletId: walletId ?? ""})
      .then((resp) => {
        setTransfers(resp)
      })
      .catch((err) => {
        setError(err)
      })
  }, [])

  // useEffect(() => {
  //   dfnsApi()
  //     .wallets.getWalletHistory({ walletId: walletId ?? ""})
  //     .then((resp) => {
  //       setHistory(resp)
  //     })
  //     .catch((err) => {
  //       setError(err)
  //     })
  // }, [])

  useEffect(() => {
    dfnsApi()
      .wallets.getApprovers({ walletId: walletId ?? ""})
      .then((resp) => {
        if(resp.items && resp.items.length > 0) {
            console.log('Fetched approvers:', resp.items);
            setApprovers(resp.items)
        }
      })
      .catch((err) => {
        setError(err)
      })
  }, [])

  useEffect(() => {
    dfnsApi()
      .wallets.getWalletAssets({ walletId: walletId ?? ""})
      .then((resp) => {
        const newAssets: Asset[] = resp.assets.map((a) => {
            return {
                kind: a.kind,
                symbol: a.symbol,
                balance: convertBalance(a.balance, a.decimals),
                verified: a.verified,
                decimals: a.decimals,
                assetId: a.kind == "Asa" ? a.assetId : undefined,
                issuer: a.kind == "Sep41" ? a.issuer : undefined,
                assetCode: a.kind == "Sep41" ? a.assetCode : undefined,
                contract: a.kind == ("Erc20" || "Trc20") ? a.contract : undefined,
                mint: a.kind == ("Spl" || "Spl2022") ? a.mint : undefined,
                master: a.kind == "Tep74" ? a.master : undefined,
                tokenId: a.kind == "Trc10" ? a.tokenId : undefined,
            }
        })
        setAssets(newAssets)
      })
      .catch((err) => {
        setError(err)
      })
  }, [])

  // useEffect(() => {
  //   dfnsApi()
  //     .wallets.listApprovals({ query: { } })
  //     .then((resp) => {
  //       setApprovals(resp.items)
  //     })
  //     .catch((err) => {
  //       setError(err)
  //     })
  // }, [])


    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleSubmitTransfer = (amount: string, to: string) => {
      setError(undefined)
        console.log('Submitted values:', amount, to);
        if(!assetCurrent) {
            setError(new DfnsError(400,"Asset Not Found"))
            return
        }
        dfnsApi()
            .wallets.transferAsset({ 
                walletId: walletId ?? "",
                body: getResponseByKind(assetCurrent, to.trim(), convertStringToBalance(amount.trim(), assetCurrent.decimals))
            })
            .then((resp) => {
              if(transfers) {
                setMessage("Approval required")

                transfers.items.unshift(resp)
                setTransfers(transfers)
              } else {
                setTransfers(transfers)
              }

            })
            .catch((err) => {
                setError(err)
                setSubmitting(false)
            })
    };

    // const handleSubmitTransaction = (amount: string, to: string) => {
    //     console.log('Submitted values:', amount, to);
    //     if(!assetCurrent) {
    //         setError(new DfnsError(400,"Asset Not Found"))
    //         return
    //     }
    //     dfnsApi()
    //         .wallets.transferAsset({ 
    //             walletId: walletId ?? "",
    //             body: getResponseByKind(assetCurrent, to, convertStringToBalance(amount, assetCurrent.decimals))
    //         })
    //         .then(() => {})
    //         .catch((err) => {
    //             setError(err)
    //             setSubmitting(false)
    //         })
    // };

  const handleDeleteApprover = (aprEmail: string) => {
    setError(undefined)
      if(!walletId) {
          setError(new DfnsError(400,"Wallet Not Found"))
          return
      }
      setSubmittingDeleteApprover(true)
      console.log(walletId, aprEmail)
      dfnsApi()
          .wallets.deleteApprover({ body: {
              walletId: walletId ?? "",
              approverEmail: aprEmail
          }})
          .then((resp) => {
            setMessage(resp.message)

            const newApprovers = approvers.filter((a) => {
              if (a.email == aprEmail) {
                return false
              }
              return true
            })
            setApprovers(newApprovers)
          })
          .catch((err) => {
              setError(err)
          })
          .finally(() => {
            setSubmittingDeleteApprover(false)
          })
  };

  const handleAddApproverOpenModal = () => setIsAddApproverModalOpen(true);
  const handleCloseAddApproverModal = () => setIsAddApproverModalOpen(false);

  const handleAddApprover = (email: string) => {
    setError(undefined)
      if(!validator.isEmail(email)) {
          setError(new DfnsError(400,"Email is required"))
          return
      }
      setSubmittingAddApprover(true)
      dfnsApi()
          .wallets.addApprover({ body: {
            walletId: walletId ?? "",
            approverEmail: email
          }
          })
          .then((resp) => {
            if (resp.status != UserStatuses.Registered) {
              setMessage("Waiting for user action")
            }
            
            if(approvers) {
              approvers.unshift(resp)
              setApprovers(approvers)
            } else {
              setApprovers(approvers)
            }

          })
          .catch((err) => {
              setError(err)
          })
          .finally(() => {
            setSubmittingAddApprover(false)
          })
  };

  // const getDecisionsByTransferId = (transferId: string) => {
  //   return approvals.find((a) => {
  //     if (a.transferId && a.transferId == transferId) {
  //       return true
  //     }
  //     return false
  //   })?.decisions ?? []
  // }

  // const getNonEmptyReason = (transferId: string, transferStatus: string, transferReason: string) => {
  //   const decisions = approvals.find((a) => {
  //     if (a.transferId && a.transferId == transferId) {
  //       return true
  //     }
  //     return false
  //   })?.decisions ?? []

  //   if (decisions.length > 0 && transferStatus != "Failed") {
  //     return decisions.find((v) => {
  //       if(v.reason != "") {
  //         return true
  //       }
  //       return false
  //     })?.reason ?? transferReason
  //   } else {
  //     return transferReason
  //   }
  // }

  // const getApprovalByTransferId = (transferId: string) => {
  //   return approvals.find((a) => {
  //     if (a.transferId && a.transferId == transferId) {
  //       return true
  //     }
  //     return false
  //   })
  // }

  return (
    <div className=" p-10 ">
      <div className="flex items-center justify-between">
        <h1 className="text-2x m-0">Wallet</h1>

        <button className="btn" type="button" onClick={logout}>
          Logout
        </button>
      </div>

        <div>
            <p className="text-2x">
                <span className='font-bold'>Wallet id:</span>   {wallet ? wallet.id : ""}
            </p>
            <p className="text-2x">
                <span className='font-bold'>Address:</span>  {wallet ? wallet.address : ""}
            </p>
        </div>

        <br />

        <p className="text-3x">
            <span className='font-bold'>Approvers</span> 
        </p>

        <table className="w-full">
          <thead>
            <tr>
              <th>Email</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {approvers &&
            approvers.map((apr) => (
              <tr key={apr.id}>
                <td>
                    <p className="text-2x">
                        {apr.email}
                    </p>
                </td>
                <td>
                    <p className="text-2x">
                        {apr.status == UserStatuses.AwaitingApprovalAdd ? 
                        "Waiting for approval to add user"
                        :
                         (apr.status == UserStatuses.AwaitingApprovalDelete ?
                            "Waiting for approval to delete user"
                            :
                            (apr.status == UserStatuses.PendingCode ?
                              "Waiting for user registration"
                              :
                              "Registered" ) )
                        }
                    </p>
                </td>
                <td>
                  {(apr.status == UserStatuses.AwaitingApprovalAdd || apr.status == UserStatuses.AwaitingApprovalDelete) ? 
                  <div></div> 
                  : (submittingDeleteApprover ?
                    <button className="btn" onClick={ () => {}}>
                      Submitting...
                    </button>
                      :
                    <button className="btn" onClick={ ()  => { handleDeleteApprover(apr.email) }}>
                      Delete
                    </button>)
                  }
                </td>
              </tr>
          ))}
          </tbody>
        </table>

        <div className="flex justify-end mt-5">
          {submittingAddApprover ?
            <button className="btn" onClick={ () => { }}>
              Submitting...
            </button>
            :
            <button className="btn" onClick={ handleAddApproverOpenModal }>
              Add Approver
            </button>
          }
        </div>

        {!!message && <div className="text-red-700">{message}</div>}
        {!!error && <div className="text-red-700">{error.message}</div>}

        <br />

        <p className="text-3x">
            <span className='font-bold'>Assets</span> 
        </p>

        <table className="w-full">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Verify</th>
              <th>Balance</th>
              <th>Tranfer</th>
            </tr>
          </thead>
          <tbody>
            {assets &&
            assets.map((asset) => (
              <tr key={asset.kind}>
                <td>
                    <p className="text-2x">
                        {asset.symbol}
                    </p>
                </td>
                <td>
                    <p className="text-2x">
                        {asset.verified}
                    </p>
                </td>
                <td>
                    <p className="text-2x">
                        {asset.balance}
                    </p>
                </td>
                <td>
                  <button className="btn" onClick={ ()  => {
                    setAssetCurrent(asset)
                    handleOpenModal()
                    }}>
                    Send
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>

        <br />

        <p className="text-3x">
            <span className='font-bold'>Transfer Requests</span> 
        </p>

        <table className="w-full">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Request Date</th>
              {/* <th>Requester</th> */}
              <th>Amount</th>
              <th>Decisions</th>
              <th>PolicyStatus</th>
              <th>Reason</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transfers &&
            transfers.items.map((trf) => (
              <tr key={trf.id}>
                <td>
                    <p className="text-2x">
                        {trf.metadata.asset.symbol}
                    </p>
                </td>
                <td>
                    <p className="text-2x">
                        {trf.dateRequested}
                    </p>
                </td>
                {/* <td>
                    <p className="text-2x">
                        {trf.requester.userId}
                    </p>
                </td> */}
                <td>
                    <p className="text-2x">
                        {(trf.requestBody.kind != "Trc721" && trf.requestBody.kind != "Erc721") ? convertBalance(trf.requestBody.amount, trf.metadata.asset.decimals ?? 1) : ""}
                    </p>
                </td>
                <td>
                  <table className="w-full">
                    <tbody>
                      { trf.decisions ? trf.decisions.items ? trf.decisions.items.map((d) => (
                          <tr key={d.email}>
                            <td>
                                <p className="text-2x">
                                    {d.email}
                                </p>
                            </td>
                            <td>
                                <p className="text-2x">
                                    {d.value}
                                </p>
                            </td>
                          </tr>
                        )) : "" : ""
                      }
                    </tbody>
                  </table>
                </td>
                <td>
                    <p className="text-2x">
                        {trf.decisions ? trf.decisions.policyDecision : ""}
                    </p>
                </td>
                
                <td>
                    <p className="text-2x">
                        {trf.reason}
                    </p>
                </td>
                <td>
                    <p className="text-2x">
                        {trf.status}
                    </p>
                </td>
              </tr>
          ))}
          </tbody>
        </table>

        <br />

        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleSubmitTransfer} />
        <ModalField label='Approver Email' isOpen={isModalAddAproverOpen} onClose={handleCloseAddApproverModal} onSubmit={handleAddApprover} />

        {!!error && <div className="text-red-700">{error.message}</div>}
      </div>
    // </form>
  )
}
