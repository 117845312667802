import { User } from '@dfns/sdk/types/auth'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import '../globals.css'
import { dfnsApi } from '../api'
import useAuth from '../hooks/useAuth'
import { DfnsError } from '@dfns/sdk/dfnsError'
import { useNavigate } from 'react-router-dom'
import { ShortApproval } from '@dfns/sdk/generated/policies/types'
import ModalField from '../components/modal_reason'
import { convertBalance } from './wallet'

export default function ApproverAction(): JSX.Element {
  const { user, logout, userId } = useAuth()
  const [submittingApprovalDecision, setApprovalDecision] = useState<boolean>(false)
  const [error, setError] = useState<DfnsError | undefined>(undefined)
  const navigate = useNavigate()
  const [approvals, setApprovals] = useState<ShortApproval[]>([])
  const [approvalsPending, setApprovalsPending] = useState<ShortApproval[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [currentAprId, setCurrentAprId] = useState<string>("")


  console.log("UserId ", userId)

  useEffect(() => {
    dfnsApi()
      .policies.listApprovals({ query: { } })
      .then((resp) => {
        const pendingApprovals = resp.items.filter((v) => {
          if (v.status == "Pending") {
            return true
          }
          return false
        })
        setApprovalsPending(pendingApprovals)
        setApprovals(resp.items)
      })
      .catch((err) => {
        setError(err)
      })
  }, [])


  const handleApprovalDecision = (value: "Approved" | "Denied", aprId: string, reason: string) => {
    setError(undefined)
    setApprovalDecision(true)
    dfnsApi()
      .policies.createApprovalDecision({
        approvalId: aprId,
        body: {
            value: value,
            reason: reason
        }
      })
      .then((resp) => {
        const newApprPending: ShortApproval[] = approvalsPending.map((a) => {
            if(a.id == aprId) {
              const newValueOpt = resp.decisions.find((v) => {
                console.log("IN ",v.userId, userId)
                const pred = v.userId == userId ? true : false 
                return pred
              })

              const newValue = newValueOpt ? newValueOpt.value : ""

              console.log("Value ",newValue, userId)
              console.log("Decions ", resp.decisions)

              a.value = newValue
              a.reason = reason
              
              return a;
            }
            return a;
        })
        setApprovalsPending(newApprPending)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setApprovalDecision(false)
      })
  }

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSubmitReason = (reason: string) => {
    console.log('Submitted values:', reason);
    
    handleApprovalDecision("Denied", currentAprId, reason)
};

  return (
    <div className="w-[900px] p-10">
      <div className="flex items-center justify-between">
        <h1 className="text-2x m-0">Approval Action</h1>

        <button className="btn" type="button" onClick={logout}>
          Logout
        </button>
      </div>

      {user && <p className="text-2x">You're logged-in as {user}. Here's a - mandatory actions for the approver:</p>}

      {!user && (
        <p className="text-2x">
          You're not logged in, you need to <a href="/login">Log in</a>
        </p>
      )}

      <table className="w-full">
        <thead>
          <tr>
            <th>Action Name</th>
            <th>Execute</th>
          </tr>
        </thead>
        <tbody>
          {approvalsPending &&
           approvalsPending.map((apr) => (
              <tr key={apr.id}>
                <td>
                    <p className="text-2x">
                        Wallet id: {apr.walletId}
                    </p>
                    <p className="text-2x">
                        Network: {apr.network}
                    </p>
                    <p className="text-2x">
                        Reason: {apr.reason}
                    </p>
                    <p className="text-2x">
                        Amount: {convertBalance(apr.amount, apr.decimals)}
                    </p>
                    <p className="text-2x">
                        Current state: {apr.value}
                    </p>
                  {/* <input 
                    className="input" 
                    id="username" 
                    name="username" 
                    placeholder="email" 
                    value={reason} 
                    onChange={handleReasonChange}
                    /> */}
                </td>
                <td>
                  {apr.value != "Pending" ? 
                    <p className="text-2x">
                        {apr.value} <br />
                        {apr.value == "Denied" ? "Reason: "+apr.reason : ""} 
                    </p> 
                    :
                    <div className="items-center gap-2">
                    <div className='p-1'>{
                      submittingApprovalDecision ?
                      <button className="btn" >
                          Submitting...
                      </button> :
                      <button className="btn" onClick={ (e) => handleApprovalDecision("Approved", apr.id, "") }>
                          Approve
                      </button>
                      }
                    </div>
                    <div className='p-1'>{
                      submittingApprovalDecision ?
                      <button className="btn" >
                          Submitting...
                      </button> :
                      <button className="btn" onClick={ (e) =>  {
                        setCurrentAprId(apr.id)
                        handleOpenModal()
                      }}>
                          Reject
                      </button>
                      }
                    </div>
                  </div>                    
                }

                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <br />

      <p className="text-3x">
          <span className='font-bold'>Approvals</span> 
      </p>

      <table className="w-full">
        <thead>
          <tr>
            <th>Date Created</th>
            <th>Expiration Date</th>
            <th>Kind</th>
            <th>Amount</th>
            <th>Decisions</th>
            {/* <th>Decision</th> */}
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {approvals &&
          approvals.map((apr) => (
            <tr key={apr.id}>
              <td>
                  <p className="text-2x">
                      {apr.dateCreated}
                  </p>
              </td>
              <td>
                  <p className="text-2x">
                      {apr.expirationDate}
                  </p>
              </td>
              <td>
                  <p className="text-2x">
                      {apr.kind}
                  </p>
              </td>
              <td>
                  <p className="text-2x">
                  {convertBalance(apr.amount, apr.decimals)}
                  </p>
              </td>
              <td>
                <table className="w-full">
                  <tbody>
                    {
                      apr.decisions.map((d) => (
                        <tr key={d.email}>
                          <td>
                              <p className="text-2x">
                                  {d.email}
                              </p>
                          </td>
                          <td>
                              <p className="text-2x">
                                  {d.value}
                              </p>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </td>
              {/* <td>
                  <p className="text-2x">
                      {apr.value}
                  </p>
              </td> */}
              <td>
                  <p className="text-2x">
                      {apr.status}
                  </p>
              </td>
            </tr>
        ))}
        </tbody>
      </table>

      <br />

      <ModalField label='Reason' isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleSubmitReason} />

      {error && <div className="text-red-700">{error.message}</div>}

    </div>
  )
}
