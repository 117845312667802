import React, { FormEvent, useState, useEffect, MouseEvent } from 'react'
import { useLocation } from 'react-router-dom';

import '../globals.css'
import useAuth from '../hooks/useAuth'
import { DfnsError } from '@dfns/sdk'
import  DropdownForm, { optionsFromStringList, DropdownOption } from '../components/dropdown'
import { dfnsApi } from '../api';
import { UserStatuses } from '../types/apptypes';
import { OrgWithApplication } from '@dfns/sdk/generated/auth';

export default function RegistrationInit(): JSX.Element {
  const { register, loading, error } = useAuth()
  const [orgIds, setOrgIds] = useState<DropdownOption[]>([])
  const [orgId, setOrgId] = useState<string>("")
  const [username, setUsername] = useState<string>("")
  const [errorBe, setError] = useState<DfnsError | undefined>(undefined)
  const [submittingForm, setSubmittingForm] = useState<boolean>(false)
  const [resultMsg, setResultMsg] = useState<string | undefined>(undefined)
  const [orgWithApp, setOrgWithApp] = useState<OrgWithApplication[]>([])
  const [appId, setAppId] = useState<string>("")

  const location = useLocation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search); 
    const usernameQ = params.get('username'); 
    const orgIdQ = params.get('orgId');
    return { usernameQ, orgIdQ };
  };

  useEffect(() => {
    const { usernameQ, orgIdQ } = getQueryParams();
    setOrgId(orgIdQ ?? "");
    setUsername(usernameQ ?? "");
  }, [])

  const handleRegistrationInit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(undefined)

    const formData = new FormData(event.currentTarget)
    const code = formData.get('code')

    // const queryParams = new URLSearchParams(window.location.search);
    // const username = queryParams.get('username');
    if (!orgId) {
      setError(new DfnsError(400, "Organization is not defined"))
      return
    } else if (!username) {
      setError(new DfnsError(400, "Username is not defined"))
      return
    } else if (!code) {
      setError(new DfnsError(400, "Code is not defined"))
      return
    }

    register(orgId, username, code as string)
  }

  const handleOptionSelect = (selected: string) => {
    setError(undefined)
    const appIdOpt = orgWithApp.find((r) => {
      if (r.orgId == selected) {
        return true
      }
      return false
    })

    if (appIdOpt) {
      localStorage.setItem('DFNS_APP_ID', appIdOpt.appId)
      setAppId(appIdOpt.appId)
      console.log("--------------->",appIdOpt.appId)
    }
    setOrgId(selected)
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleOrgList = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(undefined)

    const formData = new FormData(event.currentTarget)
    const code = formData.get('code')

    setSubmittingForm(true)

    dfnsApi()
      .auth.getOrgList({ body: { email: username ?? "", status: UserStatuses.PendingCode} })
      .then((resp) => {
        if(resp.items.length == 0) {
          // const err = new DfnsError(200, "Registration not found")
          setError(new DfnsError(200, "Organization not found"))
        } else if (resp.items.length > 1) {
          const orgIds = resp.items.map((r) => r.orgId)
          setOrgWithApp(resp.items)
          setOrgIds(optionsFromStringList(orgIds))
        } else {
          localStorage.setItem('DFNS_APP_ID', resp.items[0].appId)
          register(resp.items[0].orgId, username ?? "", code as string)
        }
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingForm(false)
      })
  }

  const handleSendNewRegistrationCode = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()

    setSubmittingForm(true)
    setError(undefined)

    dfnsApi()
      .auth.getOrgList({ body: { email: username ?? "", status: UserStatuses.PendingCode} })
      .then((resp) => {
        if(resp.items.length == 0) {
          // const err = new DfnsError(200, "Registration not found")
          setError(new DfnsError(200, "Organization not found"))
        } else if (resp.items.length > 1) {
          const orgIds = resp.items.map((r) => r.orgId)
          setOrgWithApp(resp.items)
          setOrgIds(optionsFromStringList(orgIds))
        } else {
          localStorage.setItem('DFNS_APP_ID', resp.items[0].appId)
          resendRegistrationCode(resp.items[0].orgId)
        }
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingForm(false)
      })
  }

  const resendRegistrationCode = (orgId: string) => {
    setError(undefined)
    dfnsApi()
      .auth.resendRegistrationCode({ body: {username: username ?? "", orgId: orgId}})
      .then((resp) => {
        setResultMsg(resp.message)
      })
      .catch((err) => {
        setError(err)
      })
  }

  return (
    <form onSubmit={username && orgId ? handleRegistrationInit : handleOrgList}>
      <div className="w-full p-10">
        <h1 className="text-2x">Complete registration with code</h1>

        <div className="items-center gap-2">
          <input 
            className="input" 
            id="username" 
            name="username" 
            placeholder="email" 
            value={username} 
            onChange={handleUsernameChange}
            />

          {orgIds.length > 1 ? 
            <DropdownForm options={orgIds} onOptionSelect={handleOptionSelect} />
            : <div></div>
          }

          <input className="input" id="code" name="code" placeholder="code" />

          <button className="btn" disabled={loading || submittingForm} type="submit">
            Register
          </button>
        </div>

        <br />

        <a href='' onClick={ handleSendNewRegistrationCode }>Send new registration code </a>

        {/* <p>
          You can login if your Dfns user already has a WebauthN Credential registered on this Application (this
          domain). If you don't, you first need to <a href="/credential">create a new Credential</a> for this
          Application.
        </p> */}

        <p>
        <a href="/credential">Create a new Credential</a> 
        </p>

        {!!error && error.message == "Unauthorized" && <div className="text-red-700">{"Required to resend code"}</div>}
        {!!error && error.message != "Unauthorized" && <div className="text-red-700">{error.message}</div>}
        {errorBe && <div className="text-red-700">{errorBe.message}</div>}
        {resultMsg && <div className="text-green-700">{resultMsg}</div>}
        
      </div>
    </form>
  )
}
